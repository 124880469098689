import './MobileLink.css';
import styled from 'styled-components';

const TextTitle = styled.p`
  margin-bottom: 40px;
  font-size: calc(48px + (16 * ((100vw - 375px) / 1225)));
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 100%;
   font-family: 'Quicksand', sans-serif !important;

  @media (min-width: 1920px) {
    font-size: 72px;
  }

  @media (min-width: 551px) and (max-width: 700px) {
    width: 60%;
    margin-bottom: 20px;
  }

  @media (min-width: 421px) and (max-width: 550px) {
    width: 80%;
    margin-bottom: 20px;
  }

  @media (max-width: 420px) {
    width: 100%;
    font-size: calc(40px + (16 * ((100vw - 375px) / 1225)));
    margin-bottom: 20px;
  }
`

const TextTitleVN = styled.p`
  margin-bottom: 40px;
  font-size: calc(48px + (16 * ((100vw - 375px) / 1225)));
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 100%;
   font-family: 'Quicksand', sans-serif !important;

  @media (min-width: 1920px) {
    font-size: 72px;
  }

  @media (min-width: 801px) and (max-width: 1000px) {
    font-size: calc(40px + (15 * ((100vw - 375px) / 2000)));
  }

  @media (min-width: 631px) and (max-width: 800px) {
    width: 63%;
    font-size: calc(40px + (15 * ((100vw - 375px) / 2000)));
  }

  @media (min-width: 421px) and (max-width: 630px) {
    width: 83%;
    font-size: calc(40px + (15 * ((100vw - 375px) / 2000)));
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    width: 100%;
    font-size: calc(40px + (15 * ((100vw - 375px) / 2000)));
    margin-bottom: 20px;
  }
`

function MobileLink() {
  const language = localStorage.getItem('language');

  return (
    <div id='mobile-link' className='mobile-link component-layout'>
      <div className='mobile-link-wrapper component-wrapper'>
        <div className='mobile-link-content'>
          {language === '1' ?
            <TextTitle>Download for iOS / Android</TextTitle>
            :
            <TextTitleVN>Đặt xe và theo dõi đơn</TextTitleVN>
          }

          <div className='link-app'>
            <a
              href='https://play.google.com/store/apps/details?id=vn.pippip'
              target='_blank'
              rel="noreferrer"
            >
              <img src='/Image/LinkApp/AndroidLink.png' alt='' />
            </a>
            <a
              // todo: update link appstore
              // href='https://apps.apple.com/vn/app/pippip/id1660783160'
              href='https://zalo.me/0987378533'
              target='_blank'
              rel="noreferrer"
            >
              <img src='/Image/LinkApp/ContactUs.png' alt='' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileLink;