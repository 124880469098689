import {
  service_type,
  service_type_en,
  car_type_en
} from '../data';

export default function ContractItem({ contract, index }) {
  const language = localStorage.getItem('language');

  const RenderLocation = (type) => {
    let res = '';
    contract?.contract_destination?.forEach(e => {
      if (e.type === type) {
        res = res.concat(e.location, ' => ');
      }
    });
    return res.slice(0, -3);
  }

  const GetLocation = (type) => {
    let res = [];
    contract?.contract_destination?.forEach(e => {
      if (e.type === type) {
        res.push(e.location);
      }
    });

    return res;
  }

  if (language === '1') return (
    <div className="contract-item">
      {contract?.supplier ?
        <>
          <h2 className="subtitle">{'('}Order {`${index + 1})`} Car Information</h2>
          <ul style={{ paddingLeft: '20px', listStylePosition: 'inside' }}>
            <li>
              {`Driver: ${contract?.supplier?.driver_name} - `}
              <a
                href={`tel:${contract?.supplier?.driver_phone}`}
              >
                {contract?.supplier?.driver_phone}
              </a>
            </li>
            <li>
              Car type: {contract?.supplier?.car_name}.
              Car Plates: {contract?.supplier?.car_number}
            </li>
          </ul>
        </>
        :
        null
      }
      <h2 className="subtitle">
        {contract?.supplier ? null : `(Order ${index + 1}) `}
        {service_type_en[contract?.service_type]}&nbsp;
        {contract?.contract_status < 3 && contract?.contract_status >= 0 ?
          '- Status: Confirmed'
          :
          contract?.contract_status === 3 ?
            '- Trạng thái: Drop'
            :
            null
        }
      </h2>
      <ul style={{ paddingLeft: '20px', listStylePosition: 'inside' }}>
        <li>Cartype: {car_type_en[contract?.car_type]}, Pickup time: {contract?.pickup_time}</li>
        {contract?.service_type === 4 || contract?.service_type === 5 ?
          <>
            {GetLocation(1).map((e, index) =>
              <li key={index}>Pickup: {e}</li>
            )}
            {GetLocation(2).map((e, index) =>
              <li key={index}>Drop: {e}</li>
            )}
          </>
          :
          <>
            <li>Pickup: {RenderLocation(1)}</li>
            <li>Drop: {RenderLocation(2)}</li>
          </>
        }
        {contract?.flight_number ?
          <li>Flight number: {contract?.flight_number}</li>
          :
          null
        }
        {contract?.note ?
          <li>Note: {contract?.note}</li>
          :
          null
        }
      </ul>
    </div>
  );
  else return (
    <div className="contract-item">
      {contract?.supplier ?
        <>
          <h2 className="subtitle">{'('}Chuyến {`${index + 1})`} Thông tin xe</h2>
          <ul style={{ paddingLeft: '20px', listStylePosition: 'inside' }}>
            <li>
              {`Lái xe: ${contract?.supplier?.driver_name} - `}
              <a
                href={`tel:${contract?.supplier?.driver_phone}`}
              >
                {contract?.supplier?.driver_phone}
              </a>
            </li>
            <li>
              Loại xe: {contract?.supplier?.car_name}.
              Biển số xe: {contract?.supplier?.car_number}
            </li>
          </ul>
        </>
        :
        null
      }
      <h2 className="subtitle">
        {contract?.supplier ? null : `(Chuyến ${index + 1}) `}
        Chuyến {service_type[contract?.service_type]}&nbsp;
        {contract?.contract_status < 3 && contract?.contract_status >= 0 ?
          '- Trạng thái: Đã xác nhận'
          :
          contract?.contract_status === 3 ?
            '- Trạng thái: Đã trả khách'
            :
            null
        }
      </h2>
      <ul style={{ paddingLeft: '20px', listStylePosition: 'inside' }}>
        <li>Loại xe: {contract?.car_type_name}, Thời gian đón: {contract?.pickup_time}</li>
        {contract?.service_type === 4 || contract?.service_type === 5 ?
          <>
            {GetLocation(1).map((e, index) =>
              <li key={index}>Đón: {e}</li>
            )}
            {GetLocation(2).map((e, index) =>
              <li key={index}>Đến: {e}</li>
            )}
          </>
          :
          <>
            <li>Đón: {RenderLocation(1)}</li>
            <li>Đến: {RenderLocation(2)}</li>
          </>
        }
        {contract?.flight_number ?
          <li>Số hiệu chuyến bay: {contract?.flight_number}</li>
          :
          null
        }
        {contract?.note ?
          <li>Ghi chú: {contract?.note}</li>
          :
          null
        }
      </ul>
    </div>
  )
}