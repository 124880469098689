import { useState } from 'react';
import styled from 'styled-components';

import './Header.css';

const MenuItem = styled.a`
  text-decoration: none;
  color: white;
  font-size: 60px;
  width: max-content;
  margin-bottom: 20px;

  @media (min-width: 1600px) {
    font-size: 64px;
  }

  @media (min-width: 1600px) and (min-height: 1201px) {
    font-size: 68px;
  }

  @media (min-width: 1600px) and (min-height: 1401px) {
    font-size: 72px
  }

  @media (min-width: 1600px) and (min-height: 1601px) {
    font-size: 72px
  }

  @media (max-width: 768px) {
    font-size: 44px;
    font-weight: 400;
  }
`

function Header() {
  const [scroll, setScroll] = useState(false);
  const [y, setY] = useState(0);
  const language = localStorage.getItem('language');

  window.onscroll = () => {
    const el = document.scrollingElement || document.documentElement;
    const top = el.scrollTop;
    const header = document.getElementById('header');

    if (top > 50 && !scroll) {
      setScroll(true);
      header.classList.add('scroll');
    }
    else if (top <= 50 && scroll) {
      setScroll(false);
      header.classList.remove('scroll');
    }

    if (!header.classList.contains("show")) {
      if (top > 100 && y < top) {
        header.style.top = '-100px';
      }
      else {
        header.style.top = '0px';
      }
    }

    setY(top);
  }

  const HandleOpenMenu = () => {
    document.querySelector('#header').classList.add('show');
  }

  const HandleCloseMenu = () => {
    document.querySelector('#header').classList.remove('show');
  }

  return (
    <div
      id='header'
      className='header-layer component-layout'
    >
      <div className='header-wrapper component-wrapper'>
        <div
          className='menu-layer'
        >
          <div className="sub-menu-layer">
            {language === '1' ?
              // render for english language
              <div className='menu-box'>
                <MenuItem
                  href='/'
                  onClick={HandleCloseMenu}
                >
                  Home
                  <div className='menu-item-underline' />
                </MenuItem>
                <MenuItem
                  href='https://info.pippip.vn/'
                  target='_blank'
                  rel="noreferrer"
                >
                  About Us
                  <div className='menu-item-underline' />
                </MenuItem>
                <MenuItem
                  href='/checkdonhang'
                  onClick={HandleCloseMenu}
                >
                  Check Order
                  <div className='menu-item-underline' />
                </MenuItem>
                {/* todo: add item delete(block) customer account */}
                <MenuItem
                  href='/xoataikhoan'
                  onClick={HandleCloseMenu}
                >
                  Delete Account
                  <div className='menu-item-underline' />
                </MenuItem>
              </div>
              :
              // Render for Vietnamse language
              <div className='menu-box'>
                <MenuItem
                  href='/'
                  onClick={HandleCloseMenu}
                >
                  Trang chủ
                  <div className='menu-item-underline' />
                </MenuItem>
                {/* <MenuItem
                  href='https://info.pippip.vn/'
                  target='_blank'
                  rel="noreferrer"
                >
                  Giới thiệu Pippip
                  <div className='menu-item-underline' />
                </MenuItem> */}
                <MenuItem
                  href='/checkdonhang'
                  onClick={HandleCloseMenu}
                >
                  Kiểm tra đơn hàng
                  <div className='menu-item-underline' />
                </MenuItem>
                {/* todo: add item delete(block) customer account */}
                <MenuItem
                  href='/xoataikhoan'
                  onClick={HandleCloseMenu}
                >
                  Xóa tài khoản
                  <div className='menu-item-underline' />
                </MenuItem>
              </div>
            }
            {/* <div onClick={HandleCloseMenu} className='button-X'></div> */}
          </div>
        </div>
        <img
          id='open-menu'
          className='button-menu'
          onClick={HandleOpenMenu}
          src='/Image/Menu_icon.png' alt=''
        />
        <div
          id='hide-menu'
          className='hide button-menu button-X '
          onClick={HandleCloseMenu}
        />
        <a href='/'>
          <img
            id='menu-logo-1'
            className='menu-logo'
            src='/Image/logo-pippip.png'
            alt=''
          />
          <img
            id='menu-logo-2'
            className='menu-logo'
            src='/Image/logo-pippip-2.png'
            alt=''
          />
        </a>
      </div>
    </div>
  );
}

export default Header;