// import 'antd/dist/reset.css';
import 'antd/dist/antd.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useState } from 'react';

import './App.css';
// import Header from './Component/Header/Header';
import Redirect from './Pages/Redirect';
import Home from './Pages/Home/Home';
import CheckOrder from './Pages/CheckOrder/CheckOrder';
import OrderDetail from './Pages/OrderDetail/OrderDetail';
import DeleteAccount from './Pages/DeleteAccount/DeleteAccount';
import HomeLayout from './Layout/HomeLayout';
// import Footer from './Component/Footer/Footer';
import CheckLayout from './Layout/CheckLayout';
import DownloadApp from './Pages/DownloadApp';
import DeleteLayout from './Layout/DeleteLayout';


function App() {
  const [ orderDetail, setOrderDetail ] = useState();
  const [ orderCode, setOrderCode ] = useState('');
  // type = true is combo contract, type = false is order airport
  const [ type, setType ] = useState();

  return (
    <div id='App' className="App">
      {/* <Header /> */}
      <BrowserRouter>
        <Routes>
          <Route
            path='*'
            element={<Redirect />}
          />
          <Route 
            path='/checkdonhang' 
            element={<CheckLayout>
              <CheckOrder
                setOrderDetail={setOrderDetail}
                orderCode={orderCode}
                setType={setType}
              />
            </CheckLayout>}
          />
          <Route 
            path='/checkdonhang/:orderCode'
            element={<CheckLayout>
              <OrderDetail
                data={orderDetail}
                setOrderCode={setOrderCode}
                type={type}
              />
            </CheckLayout>}
          />
          {/*todo: add delete(block) cutomer account */}
          <Route 
            path='/xoataikhoan' 
            element={<DeleteLayout>
              <DeleteAccount/>
            </DeleteLayout>}
          />
          <Route 
            path='/'
            element={<HomeLayout>
              <Home />
            </HomeLayout>}
          />
          <Route 
            path='/appdownload'
            element={<DownloadApp />}
          />
        </Routes>
      </BrowserRouter>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
